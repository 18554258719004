import request from '@/utils/request';
import consts from '@/utils/consts';

// 个人中心：获取用户信息(包含点头像进去的个人信息)
export function apiGetUserInfo(data) {
	return request({
		url: consts.businessPublic + '/user/resume',
		method: 'post',
		data: data
	})
}


// 查询是否有未读消息
export function apiGetNoticeHasUnread(data) {
	return request({
		url: consts.businessPublic + '/notice/hasUnread',
		method: 'post',
		data: data
	})
}
// 分页获取消息通知
export function apiGetNoticeOnePage(data) {
	return request({
		url: consts.businessPublic + '/notice/getNoticeOnePage',
		method: 'post',
		data: data
	})
}
// 批量标记为已读
export function apiReadMarkBatch(data) {
	return request({
		url: consts.businessPublic + '/notice/readMarkBatch',
		method: 'post',
		data: data
	})
}
// 我的-跟单赛事
export function getBetEventList(data) {
	return request({
		url: consts.businessPublic + '/plan/getBetEventList',
		method: 'post',
		data: data
	})
}
