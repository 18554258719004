<template>
	<div class="mine app_page app_flex" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box;' : ''">
		<div class="app_scroll mine_content">
			<div class="mine_content_top">
				<div class="top_l">
					<img class="top_img" :src="oMyInfo.head ? oMyInfo.head : defaultAvatar" alt="" @click="toZhuye()">
					<div class="top_name">
						<div class="name_top" @click="fGoPath('selectRole')">
							<div class="top_l">{{oMyInfo.roleName}}</div>
							<div class="top_r">更换角色</div>
							<img src="~@/assets/icon/icon-100.png" alt="">
						</div>
						<div class="name_bot">
							{{oMyInfo.accountNo}}
						</div>
					</div>
				</div>
<!--				<div class="top_r">-->
<!--					<img src="~@/assets/images/new/mine_001.png" alt="">-->
<!--				</div>-->
			</div>
			<div class="mine_content_img">
				<div class="mine_content_img_box">
					<div class="mine_content_img_item" @click="fensi">
						<div class="mine_content_img_item1">粉丝</div>
						<div class="mine_content_img_item2">{{oMyInfo.fansCount}}</div>
					</div>
					<div class="mine_content_img_shu"></div>
					<div class="mine_content_img_item" @click="guanzhu">
						<div class="mine_content_img_item1">关注</div>
						<div class="mine_content_img_item2">{{oMyInfo.favoriteCount}}</div>
					</div>
					<div class="mine_content_img_shu"></div>
<!--					<div class="mine_content_img_item" @click="fGoPath('xiaoxi')">-->
<!--						<div class="mine_content_img_item1">消息</div>-->
<!--						<div class="mine_content_img_item3">-->

<!--							<img src="~@/assets/images/new/mine_004.png" alt="">-->
<!--							<div v-if="oMyInfo.newsCount > 0">+{{oMyInfo.newsCount}}</div>-->
<!--						</div>-->
<!--					</div>-->
					<div class="mine_content_img_item4">
						<div class="item_box" @click="toZhuye()">
							<img src="~@/assets/images/new/mine_002.png" alt="">
							<div>我的主页</div>
						</div>
					</div>
				</div>


			</div>
			<div class="mine_content_list">

				<div class="list_item" @click="fGoPath('recordGD')">
					<div class="list_item_left">
						<img src="~@/assets/icon/icon-105.png" alt="">
						<p>已选赛事</p>
					</div>
					<div class="list_item_right">
						<img src="~@/assets/icon/icon-007.png" alt="">
					</div>
				</div>
				<div class="list_item" @click="fGoPath('setup')">
					<div class="list_item_left">
						<img src="~@/assets/images/new/mine_005.png" alt="">
						<p>设置</p>
					</div>
					<div class="list_item_right">
						<img src="~@/assets/icon/icon-007.png" alt="">
					</div>
				</div>
				<div class="list_item" @click="fCopyQQ()">
					<div class="list_item_left">
						<img src="~@/assets/icon/icon-107.png" alt="">
						<p>客服QQ</p>
					</div>

					<div class="list_item_right">
						<span>2963252414</span>
						<img src="~@/assets/icon/icon-007.png" alt="">
					</div>
				</div>





			</div>
		</div>



	</div>
</template>

<script>
	import {
		ref,
		reactive,
		onActivated,
		inject
	} from "vue";
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	import {
		fnCopyInnerText,
		uaFun
	} from '@/utils/public.js';
	import tipsContent from '@/utils/tipsContent';
	import {
		apiGetUserInfo,
		apiGetNoticeHasUnread,
	} from '@/api/mine.js';

	import defaultAvatar from "@/assets/images/img_032.png";
	export default {
		name: "Mine",
		components: {
		},
		setup() {
			const isApp = uaFun()
			const route = useRoute();
			const router = useRouter(); //初始化路由
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			const fGlobalReload = inject("fGlobalReload");
			// 我的信息
			const oMyInfo = ref(store.state.userInfo.myInfo);
			// 获取我的信息
			const fGetUserInfo = () => {
				Toast({
					type: "loading"
				});
				apiGetUserInfo({
					roleId: UserInfo.roleId,
					userId: UserInfo.userId
				}).then(res => {
					oMyInfo.value = res && res.result ? res.result : {};
					store.dispatch("userInfo/UMyInfo", oMyInfo.value);
					UserInfo.isSync = oMyInfo.value.isSync ? true : false;
					store.dispatch("userInfo/UUserInfo", UserInfo);
				})
			};
			// 当前页面数据
			const oCPData = reactive({
				hasUnreadNotice: false,
				eyeShow: store.state.other.eyeShow,
				oYCData: {
					dialogA: {
						showDialog: false,
						showCancelButton: false,
						showConfirmButton: false
					},
					dialogB: {
						showDialog: false,
						showCancelButton: false,
						showConfirmButton: false
					},
					dialogC: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					},
					dialogD: {
						showDialog: false,
						showCancelButton: false,
						confirmButtonText: "确定"
					},
				}
			})
			// 查询是否有未读消息
			const fGetNoticeHasUnread = () => {
				apiGetNoticeHasUnread({
					id: UserInfo.roleId,
					userId: UserInfo.userId
				}).then(res => {
					oCPData.hasUnreadNotice = res && res.result ? res.result.hasUnreadNotice : false;
				})
			};

			// 前往地址
			const fGoPath = (Path) => {
				console.log(Path);
				if ( Path === "recharge" && oMyInfo.value.rechargeWay === "QRCODE") {
					router.push({
						path: '/web',
						query: {
							type: "recharge",
							url: oMyInfo.value.rechargeUrl
						}
					});
				} else {
					router.push({
						path: '/' + Path,
						query: {
							redirect: "mine"
						}
					});
				}
			};
			const toZhuye = () =>{
				// 跳转到方案详情
				router.push({
					path: '/zhuye',
					query: {
						id: UserInfo.roleId
					}
				})
			}
			// 前往web页面
			const fGoWeb = (Web, Url) => {
				if (Url) {
					router.push({
						path: '/web',
						query: {
							type: Web,
							url: Url
						}
					});
				} else {
					router.push({
						path: '/web',
						query: {
							type: Web
						}
					});
				}
			};
			// 复制联系方式
			const fCopyQQ = () => {
				let tData = fnCopyInnerText("2963252414");
				if (tData) {
					Toast({
						message: tipsContent.copySuc,
						position: 'bottom'
					});
				} else {
					Toast({
						message: tipsContent.copyFail,
						position: 'bottom'
					});
				}
			};
			// 小眼睛开关
			const fChangeEyeShow = (TF) => {
				oCPData.eyeShow = TF;
				store.dispatch('other/UEyeShow', TF);
			};





			// 生命周期
			onActivated(() => {
				console.log("已经进入mine页面")
				const route_meta = route.meta;
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fGlobalReload();
				} else if (route_meta.resetType === '0') {
					route_meta.resetType = '1'
					fGetUserInfo();
					fGetNoticeHasUnread();
				}
			});
			const fensi = () =>{
				router.push({
					path: '/fensi',
					query: {
						id: UserInfo.roleId,
						userId:UserInfo.userId
					}
				})
			}
			const guanzhu = () =>{
				router.push({
					path: '/guanzhu',
					query: {
						id: UserInfo.roleId,
						userId:UserInfo.userId
					}
				})
			}
			return {
				oMyInfo,
				oCPData,
				fensi,
				guanzhu,
				isApp,

				fChangeEyeShow,
				toZhuye,

				fCopyQQ,
				fGoPath,
				fGoWeb,
				defaultAvatar,
			}
		}
	}
</script>

<style scoped lang="scss">
	.mine {
		width: 100%;
		height: 100%;
		background: #ffffff;
		background-image: url('~@/assets/images/img_100.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
	}

	.mine_content {
		width: 100%;
		height: 100%;
		padding: 0.3733rem 0.293rem 1.84rem;
		box-sizing: border-box;

		.mine_content_top {
			width: 100%;
			height: 1.333rem;
			padding-left: 0.133rem;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			margin-bottom: 0.693rem;

			.top_l {
				display: flex;

				.top_img {
					width: 1.28rem;
					height: 1.28rem;
					background: rgba(158, 97, 30, 0.15);
					border-radius: 50%;
					margin-right: 0.213rem;
				}

				.top_name {
					padding-top: 0.16rem;
					box-sizing: border-box;

					.name_top {
						display: flex;
						align-items: center;
						margin-bottom: 0.267rem;

						.top_l {
							font-size: 0.427rem;
							font-weight: 600;
							color: #9E611E;
							margin-right: 0.32rem;
						}

						.top_r {
							font-size: 0.267rem;
							font-weight: 600;
							color: #9E611E;
							margin-right: 0.107rem;
						}

						img {
							width: 0.16rem;
							height: 0.267rem;
						}
					}

					.name_bot {
						font-size: 0.267rem;
						font-weight: 400;
						color: #9E611E;
					}
				}
			}

			.top_r {
				display: flex;
				align-items: center;
				img{
					width: 0.427rem;
					height: 0.427rem;
				}

				.l {
					display: flex;
					justify-content: flex-end;
					position: relative;

					div {
						width: 0.107rem;
						height: 0.107rem;
						background: #E2335D;
						position: absolute;
						right: -0.08rem;
						top: -0.08rem;
						border-radius: 50%;
					}

					img {
						width: 0.453rem;
						height: 0.4rem;
					}
				}

				.r {
					padding: 0 0.08rem;
					box-sizing: border-box;
					height: 0.453rem;
					background: linear-gradient(270deg, #DCAD83 0%, #9E611E 100%);
					border-radius: 0px 0.213rem 0px 0.213rem;
					line-height: 0.453rem;
					font-size: 0.267rem;
					font-weight: 400;
					color: #FFFFFF;
					text-align: center;
				}
			}
		}

		.mine_content_img {
			width: 100%;
			height: 3.067rem;
			background-image: url('~@/assets/images/new/mine_003.png');
			background-size: 100% 3.067rem;
			background-repeat: no-repeat;
			margin-bottom: 0.16rem;
			.mine_content_img_box{
				width: 100%;
				height: 2.533rem;
				display: flex;
				align-items: center;
				.mine_content_img_shu{
					width: 0.027rem;
					height: 0.747rem;
					background:rgba(255, 255, 255, 0.14);
				}
				.mine_content_img_item{
					width: 2.027rem;
					height: 100%;
					display: flex;
					flex-direction:column;
					align-items: center;
					justify-content: center;
					.mine_content_img_item1{
						font-size: 0.32rem;
						font-weight: 400;
						color: #FFFFFF;
						margin-bottom: 0.213rem;
					}
					.mine_content_img_item2{
						font-size: 0.64rem;
						font-weight: bold;
						color: #FFFFFF;
					}
					.mine_content_img_item3{
						width: 0.427rem;
						height: 0.493rem;
						display: flex;
						margin-top: 0.213rem;
						img{
							width: 0.427rem;
							height: 0.493rem;
						}
						div{
							font-size: 0.267rem;
							font-weight: bold;
							color: #FFFFFF;
							margin-top: -0.267rem;

						}
					}
				}
				.mine_content_img_item4{
					width: calc(100% - 4.09rem);
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					.item_box{
						width: 2.373rem;
						height: 0.667rem;
						background: #EEC89D;
						border-radius: 0.427rem;
						display: flex;
						align-items: center;
						justify-content: center;
						img{
							width: 0.4rem;
							height: 0.347rem;
							margin-right: 0.187rem;
						}
						div{
							font-size: 0.32rem;
							font-weight: 500;
							color: #7B460D;
						}
					}
				}
			}

		}

		.mine_content_list {
			margin: 0 0.417rem;

			.list_item {
				width: 100%;
				height: 1.493rem;
				border-bottom: 0.027rem solid #F5F5F5;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.list_item_left {
					display: flex;
					align-items: center;

					&>img {
						width: 0.693rem;
						height: 0.693rem;
						margin-right: 0.213rem;
					}

					&>p {
						font-size: 0.373rem;
						font-weight: 400;
						color: #383838;

					}
				}

				.list_item_right {
					display: flex;
					align-items: center;

					&>span {
						font-size: 0.32rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.4266rem;
						margin-right: 0.2133rem;
					}

					&>img {
						width: 0.16rem;
						height: 0.267rem;
					}
				}
			}
		}
	}

	.mine_tips_a {
		padding: 0.8rem 0.64rem;

		.mine_tips_a_a {
			font-size: 0.4266rem;
			font-weight: bold;
			color: #383838;
			line-height: 0.5866rem;

			&>em {
				font-weight: bold;
				color: #E2335D;
			}
		}

		.mine_tips_a_b {
			font-size: 0.3733rem;
			font-weight: 400;
			color: #979797;
			line-height: 0.5866rem;
		}
	}

	.mine_tips_b {
		padding: 0.2666rem 0.8533rem;
		font-size: 0.4266rem;
		font-weight: 400;
		color: #383838;
		line-height: 0.5866rem;
	}

	.mine_tips_c {
		text-align: center;
		padding: 0.2666rem 0.8533rem;
		font-size: 0.4266rem;
		font-weight: 400;
		color: #383838;
		line-height: 0.5866rem;
	}
</style>
