<template>
	<div class="recordFD app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar class="recordFD_bar" title="发单记录" left-arrow @click-left="fLeft">
					<template #right>
						<div class="HDropdownMenu">
							<div class="HDropdownMenu_show"
								:class="{'HDropdownMenu_show--select' : oPlanStatus.select.name !== '全部'}"
								@click="fOpenPlanStatus">
								<span>{{oPlanStatus.select.name !== "全部" ? oPlanStatus.select.name : "筛选" }}</span>
								<img v-if="oPlanStatus.select.name === '全部'" src="~@/assets/icon/icon-023.png">
								<img v-else src="~@/assets/icon/icon-026.png">
							</div>
							<div class="HDropdownMenu_content" v-if="oPlanStatus.show">
								<div class="HDropdownMenu_content_title" @click="fClosePlanStatus">
									<span>筛选</span>
									<img src="~@/assets/icon/icon-024.png">
								</div>
								<p v-for="(item,index) in oPlanStatus.option" :key="index"
									class="HDropdownMenu_content_option"
									:class="{'HDropdownMenu_content_option--selected' : item.name === oPlanStatus.select.name}"
									@click="fSelectPlanStatus(item)">{{item.name}}</p>
							</div>
							<div class="HDropdownMenu_mask" v-if="oPlanStatus.show" @click="fClosePlanStatus"></div>
						</div>
					</template>
				</van-nav-bar>
			</van-sticky>
		</div>
		<div class="mt12 recordFD_body app_flex">
			<div class="recordFD_body_total">
				<div>
					<p>￥{{oDetail.totalCommission}}</p>
					<p>总佣金</p>
				</div>
				<div>
					<p>{{oDetail.totalNumber}}</p>
					<p>总跟单人数</p>
				</div>
				<div>
					<p>￥{{oDetail.receivedCommission}}</p>
					<p>已收佣金</p>
				</div>
				<div>
					<p>￥{{oDetail.unpayCommission}}</p>
					<p>待收佣金</p>
				</div>
			</div>

			<div v-if="oRecord.list.length > 0" class="app_scroll">
				<van-list v-model:loading="oRecord.loading" :finished="oRecord.finished" v-model:error="oRecord.error"
					error-text="请求失败，点击重新加载" finished-text="没有更多了" @load="fOnLoad">
					<div class="recordFD_list" v-for="(item, index) in oRecord.list" :key="index">
						<div class="recordFD_list_a">
							<div class="recordFD_list_a_a">

								<div v-if="item.lotteryPlanType=='竞彩足球'" class="recordFD_list_a_a_a--blue"></div>
								<div v-else class="recordFD_list_a_a_a--red"></div>
								<span
									class="recordFD_list_a_a_b">{{item.lotteryPlanType =='竞彩足球' ? "竞彩足球":"竞彩篮球"}}</span>
								<span class="recordFD_list_a_a_c">{{item.lotteryPlanSubType}}</span>
							</div>
							<div class="recordFD_list_a_b">
								<span
									class="recordFD_list_a_b_a">{{item.isWinning === false ? "未中奖" : fnRecordGDStatusCode(item.planStatus)}}</span>
							</div>
						</div>

						<div class="recordFD_list_b">
							<div class="recordFD_list_b_a">
								<p>发单时间 {{fnFormatData(item.created, "MM-dd hh:mm")}}</p>
								<p>方案编号：{{item.planNo}}</p>
							</div>
							<div class="recordFD_list_b_b">
								<span class="recordFD_list_b_b_a" @click="fGoDetail(item.id)">跟单列表</span>
							</div>
						</div>
					</div>
				</van-list>
			</div>
			<div v-else class="app_scroll">
				<van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')"
					description="目前没有发单记录…" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		reactive
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRouter
	} from "vue-router";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnFormatData,
		fnRecordGDStatusCode
	} from "@/utils/public.js";
	import {
		apiGetPlanBillRecordDetail,
		apiGetPlanBillRecordOnePage
	} from '@/api/mine.js';
	export default {
		name: "RecordFD",
		setup() {
			const router = useRouter(); //初始化路由
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;

			// 详情
			const oDetail = reactive({
				receivedCommission: "0.00",
				totalCommission: "0.00",
				totalNumber: 0,
				unpayCommission: "0.00"
			})
			// 获取详情
			const fGetDetail = () => {
				apiGetPlanBillRecordDetail({
					id: UserInfo.roleId
				}).then(res => {
					let tData = res.result ? res.result : {};
					oDetail.receivedCommission = tData.receivedCommission;
					oDetail.totalCommission = tData.totalCommission;
					oDetail.totalNumber = tData.totalNumber;
					oDetail.unpayCommission = tData.unpayCommission;
				})
			};


			// 方案状态
			const oPlanStatus = reactive({
				show: false,
				select: {
					id: "",
					name: "全部"
				},
				option: [{
					id: "",
					name: "全部"
				}, {
					id: 1,
					name: "待开奖"
				}, {
					id: 2,
					name: "未中奖"
				}, {
					id: 3,
					name: "兑奖中"
				}, {
					id: 4,
					name: "已兑奖"
				}]
			});
			// 打开方案状态
			const fOpenPlanStatus = () => {
				console.log("方案状态");
				oPlanStatus.show = true;
			};
			// 关闭方案状态
			const fClosePlanStatus = () => {
				console.log("方案状态");
				oPlanStatus.show = false;
			};
			// 选择方案状态
			const fSelectPlanStatus = (Item) => {
				if (Item.name !== oPlanStatus.select.name) {
					oPlanStatus.select = Item;
					fOnLoadReset();
				}
				oPlanStatus.show = false;
			};


			const oRecord = reactive({
				current: 0,
				size: 10,
				list: [],
				error: false,
				loading: false,
				finished: false
			});
			const fOnLoad = () => {
				const current = oRecord.current + 1;
				apiGetPlanBillRecordOnePage({
					roleId: UserInfo.roleId,
					planNo: "",
					planStatus: oPlanStatus.select.id,
					current,
					size: oRecord.size
				}).then(res => {
					oRecord.current = current;
					let tData = res && res.result ? res.result : {};
					let tRecords = tData.records ? tData.records : [];
					if (tRecords.length > 0) {
						oRecord.list.push(...tRecords);
						if (tData.hasNext) {
							oRecord.finished = false;
						} else {
							oRecord.finished = true;
						}
					} else {
						oRecord.finished = true;
					}
					oRecord.loading = false;
				}).catch(() => {
					oRecord.loading = false;
					oRecord.error = true;
				})
			};

			// 搜索重置
			const fOnLoadReset = () => {
				oRecord.current = 0;
				oRecord.size = 10;
				oRecord.list = [];
				oRecord.error = false;
				oRecord.loading = false;
				oRecord.finished = false;
				fOnLoad();
			};

			// 前往跟单列表
			const fGoDetail = (Id) => {
				router.push({
					path: '/listGDR',
					query: {
						id: Id
					}
				});
			};
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/mine");
			};
			
			
			fOnLoad();
			fGetDetail();

			return {
				oDetail,

				oRecord,
				fOnLoad,

				oPlanStatus,
				fOpenPlanStatus,
				fClosePlanStatus,
				fSelectPlanStatus,
				
				fGoDetail,
				
				fnFormatData,
				fnRecordGDStatusCode,
				fLeft
			}
		}
	}
</script>

<style scoped lang="scss">
	.recordFD {
		width: 100%;
		height: 100%;
		background: #F1F2F7;
		box-sizing: border-box;

		.recordFD_body {
			flex: 1;
			overflow: hidden;
			box-sizing: border-box;
			background: #FAFBFF;
			box-shadow: 0px 0.0533rem 0.4266rem 0px rgba(224, 228, 246, 0.5);
			border-radius: 0.32rem 0.32rem 0px 0px;

			.recordFD_body_total {
				margin: 0.2133rem 0.1866rem 0.4266rem;
				display: flex;
				align-items: center;

				&>div {
					flex: 1;
					padding: 0.2666rem;
					text-align: center;
					position: relative;

					&>p:first-child {
						font-size: 0.4rem;
						font-weight: bold;
						color: #E2335D;
					}

					&>p:last-child {
						font-size: 0.32rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.4533rem;
						margin-top: 0.1066rem;
					}
				}

				&>div:not(:last-child):after {
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					content: "";
					width: 0.0266rem;
					height: 0.56rem;
					background: #EAEAEA;
				}
			}

			.recordFD_list {
				margin: 0 0.32rem 0.32rem;
				padding: 0.4rem;
				background: #FFFFFF;
				box-shadow: 0px 0.0533rem 0.2133rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.2133rem;

				.recordFD_list_a {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 0.4266rem;

					.recordFD_list_a_a {
						display: flex;
						align-items: center;

						.recordFD_list_a_a_a--red {
							width: 0.1066rem;
							height: 0.48rem;
							background: #F21B8E;
							border-radius: 0.0533rem;
						}

						.recordFD_list_a_a_a--blue {
							width: 0.1066rem;
							height: 0.48rem;
							background: #033EC2;
							border-radius: 0.0533rem;
						}

						.recordFD_list_a_a_b {
							margin-left: 0.1066rem;
							font-size: 0.3733rem;
							font-weight: 600;
							color: #383838;
						}

						.recordFD_list_a_a_c {
							margin-left: 0.1066rem;
							font-size: 0.3733rem;
							font-weight: 400;
							color: #383838;
						}
					}

					.recordFD_list_a_b {
						.recordFD_list_a_b_a {
							font-size: 0.32rem;
							font-weight: 500;
							color: #E2335D;
						}
					}
				}

				.recordFD_list_b {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;

					.recordFD_list_b_a {
						&>p {
							font-size: 0.32rem;
							font-weight: 400;
							color: #979797;
							line-height: 0.4533rem;
							margin-bottom: 0.0533rem;
						}
					}

					.recordFD_list_b_b {
						.recordFD_list_b_b_a {
							padding: 0.0533rem 0.2133rem;
							border-radius: 0.4266rem;
							border: 0.0266rem solid #383838;

							font-size: 0.32rem;
							font-weight: 400;
							color: #383838;
							line-height: 0.48rem;
						}
					}
				}
			}
		}
	}
</style>
