<template>
	<div class="setupPw app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="设置密码" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="setupPw_body mt12 app_scroll">
			<van-form class="setupPw_form" submit-on-enter label-width="2.5866rem" @submit="fSubmit">
				<van-field v-model="oForm.password" type="password" name="password" label="输入新密码"
					placeholder="请输入6-16位新密码" maxlength="16" input-align="right" :border="false"
					:rules="[{ validator:rules.password.validator }]" />
				<van-field v-model="oForm.passwordSame" type="password" name="passwordSame" label="确认新密码"
					placeholder="请重新输入6-16位新密码" maxlength="16" input-align="right" :border="false"
					:rules="[{ validator:rules.passwordSame.validator }]" />

				<div class="setupPw_bottom">
					<van-button round block native-type="submit" class="app_button" :disabled="oLoginBtn.isDisabled">
						确认
					</van-button>
				</div>
			</van-form>
		</div>

	</div>
</template>

<script>
	import {
		reactive,
		inject,
		onActivated,
	} from "vue";
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnKeyIsTrue
	} from "@/utils/public.js";
	import {
		fnValidStrLength,
		fnIsEN
	} from '@/utils/validate.js';
	import tipsContent from '@/utils/tipsContent';
	export default {
		name: "SetupPw",
		setup() {
			const route = useRoute();
			const router = useRouter();
			const fnGlobalReload = inject("fGlobalReload");
			const store = useStore(); // 使用useStore方法
			// 规则
			const rules = {
				password: {
					validator: (val) => {
						if (val.length >= 6) {
							oLoginBtn.isTrue.password = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.password = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				},
				passwordSame: {
					validator: (val) => {
						if (val.length >= 6) {
							oLoginBtn.isTrue.passwordSame = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.passwordSame = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				}
			};

			onActivated(() => {
				const route_meta = route.meta;
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fnGlobalReload();
				} else if (route_meta.resetType === '0') {
					route_meta.resetType = '1';
				}
			});

			// 登录按钮包含属性
			const oLoginBtn = reactive({
				// 所有验证全部通过
				isTrue: {
					password: false,
					passwordSame: false,
				},
				// 是否禁用
				isDisabled: true
			});

			//表单字段
			const oForm = reactive({
				password: null,
				passwordSame: null,
			});

			// 表单提交触发事件
			const fSubmit = () => {
				// 密码规则验证
				if (!fnValidStrLength(oForm.password)) {
					return Toast({
						message: tipsContent.formatPassword,
						position: 'bottom'
					});
				} else if (!fnIsEN(oForm.password)) {
					return Toast({
						message: tipsContent.formatPassword,
						position: 'bottom'
					});
				}
				// 确认密码规则验证
				if (!fnValidStrLength(oForm.passwordSame)) {
					return Toast({
						message: tipsContent.formatPassword,
						position: 'bottom'
					});
				} else if (!fnIsEN(oForm.passwordSame)) {
					return Toast({
						message: tipsContent.formatPassword,
						position: 'bottom'
					});
				}
				// 两次密码是否一致
				if (oForm.password != oForm.passwordSame) {
					return Toast({
						message: tipsContent.diffPassword,
						position: 'bottom'
					});
				}
				Toast({
					type: "loading"
				});
				store.dispatch("userInfo/SetupPw", {
					password: oForm.password,
					confirmPassword: oForm.passwordSame,
				}).then(() => {
					Toast({
						message: "操作成功！",
						position: 'bottom'
					});
					router.replace({
						path: "/setup"
					});
				})
			};

			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/")
			};


			return {
				rules,
				oLoginBtn,
				oForm,

				fSubmit,
				fLeft,
			};
		}
	}
</script>

<style scoped lang="scss">
	.setupPw {
		width: 100%;
		height: 100%;
		background: #F1F2F7;

		.setupPw_body {
			position: relative;

			padding: 0 0.32rem 0;

			.setupPw_bottom {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				padding: 0.4266rem;
				background: #FFFFFF;
				border-radius: 0.2133rem 0.2133rem 0px 0px;
			}
		}
	}
</style>
