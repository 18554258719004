<template>
	<div class="capitalAll app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="资金明细" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div v-if="aCapitalAll.list.length > 0" class="capitalAll_body app_scroll">
			<van-list v-model:loading="aCapitalAll.loading" v-model:error="aCapitalAll.error" error-text="请求失败，点击重新加载"
				:finished="aCapitalAll.finished" finished-text="没有更多了" @load="fOnLoad">
				<div class="capitalAll_body_item" v-for="(item,index) in aCapitalAll.list" :key="index">
					<div class="capitalAll_body_item_a">
						<p class="capitalAll_body_item_a_a">{{item.amountType}}</p>
						<p class="capitalAll_body_item_a_b" :class="{'cE2335D':item.direction == '1'}">{{item.direction == '1'? '+' :'-'}}{{item.amount}}</p>
					</div>
					<p class="capitalAll_body_item_b">{{fnFormatData(item.created, "yyyy-MM-dd hh:mm")}}</p>
				</div>
			</van-list>
		</div>
		<div v-else class="app_scroll">
			<van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')"
				description="还没有资金明细…" />
		</div>
	</div>
</template>

<script>
	import {
		reactive
	} from "vue";
	import {
		useStore
	} from "vuex";
	import {
		apiCapitalDetail
	} from "@/api/mine.js";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnFormatData
	} from "@/utils/public.js";
	export default {
		name: "CapitalAll",
		setup() {
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			// 方案--全部
			const aCapitalAll = reactive({
				current: 0,
				size: 10,
				list: [],
				error: false,
				loading: false,
				finished: false
			});
			// 加载完成--方案--待支付
			const fOnLoad = () => {
				const current = aCapitalAll.current + 1;

				apiCapitalDetail({
					roleId: UserInfo.roleId,
					userId: UserInfo.userId,
					current,
					size: aCapitalAll.size
				}).then(res => {
					aCapitalAll.current = current;
					let tData = res && res.result ? res.result : {};
					let tRecords = tData.records ? tData.records : [];
					if (tRecords.length > 0) {
						aCapitalAll.list.push(...tRecords);
						if (tData.hasNext) {
							aCapitalAll.finished = false;
						} else {
							aCapitalAll.finished = true;
						}
					} else {
						aCapitalAll.finished = true;
					}
					aCapitalAll.loading = false;
				}).catch(() => {
					aCapitalAll.loading = false;
					aCapitalAll.error = true;
				})
			};

			const fLeft = () => {
				fnIsHasHistory("/mine");
			};
			fOnLoad();
			return {
				aCapitalAll,

				fnFormatData,
				fOnLoad,

				fLeft,
			}
		}
	}
</script>

<style scoped lang="scss">
	.capitalAll {
		width: 100%;
		height: 100%;
		background: #F1F2F7;

		.capitalAll_body {
			padding: 0.32rem;
			.capitalAll_body_item {
				padding: 0.4rem 0.4rem 0;
				margin-bottom: 0.32rem;
				
				background: #FFFFFF;
				box-shadow: 0px 0.0533rem 0.2133rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.2133rem;
				.capitalAll_body_item_a {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-bottom: 0.7733rem;
					border-bottom: 0.0266rem solid #EAEAEA;
					
					.capitalAll_body_item_a_a {
						font-size: 0.3733rem;
						font-weight: 500;
						color: #383838;
						line-height: 0.5333rem;
					}
					.capitalAll_body_item_a_b {
						font-size: 0.4266rem;
						font-weight: bold;
						color: #383838;
						line-height: 0.5066rem;
					}
				}
				.capitalAll_body_item_b {
					padding: 0.2133rem 0;
					font-size: 0.32rem;
					font-weight: 400;
					color: #979797;
					line-height: 0.4533rem;
				}
			}
		}
	}
</style>
