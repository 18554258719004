<template>
    <div class="recordGD app_flex" style="background: #F1F2F7;">
        <div class="app_title_b">
            <van-sticky>
                <van-nav-bar class="recordGD_bar" title="消息" left-arrow @click-left="fLeft">
                </van-nav-bar>
            </van-sticky>
        </div>
<!--        <div class="xiaoxi">-->
<!--            <div class="xiaoxi_top">-->
<!--                <div class="xiao_top_item" @click="dian('1')">-->
<!--                    <div class="xiao_top_item_f">-->
<!--                        全部-->
<!--                    </div>-->
<!--                    <div class="xiao_top_item_x"></div>-->
<!--                </div>-->
<!--                <div class="xiao_top_item" @click="dian('2')">-->
<!--                    <div class="xiao_top_item_f">-->
<!--                        系统通知-->
<!--                    </div>-->
<!--                    <div class="xiao_top_item_x"></div>-->
<!--                </div>-->
<!--                <div class="xiao_top_item" @click="dian('3')">-->
<!--                    <div class="xiao_top_item_f">-->
<!--                        评论-->
<!--                    </div>-->
<!--                    <div class="xiao_top_item_x"></div>-->
<!--                </div>-->
<!--                <div class="xiao_top_item" @click="dian('4')">-->
<!--                    <div class="xiao_top_item_f">-->
<!--                        点赞-->
<!--                    </div>-->
<!--                    <div class="xiao_top_item_x"></div>-->
<!--                </div>-->

<!--            </div>-->
<!--        </div>-->

<!--        <div  class="mt12 recordGD_body app_scroll">-->
<!--            <van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')" description="暂时没有数据" />-->
<!--        </div>-->
    </div>
</template>

<script>
    import {
        reactive
    } from 'vue';
    import {
        fnIsHasHistory
    } from "@/utils/router.js";
    export default {
        name: "xiaoxi",
        components: {
            // HDialogC
        },
        setup() {
            const fLeft = () => {
                fnIsHasHistory("/mine");
            };

            // 当前页面数据
            const oCPData = reactive({
                oYCData: {

                }
            })
            // const dian = function (e) {
            //     // if(e == '1'){
            //     //
            //     // }
            // };

            return {
                oCPData,
                fLeft,
                // dian
            }
        }
    }
</script>

<style scoped lang="scss">
    .xiaoxi{
        width: 100%;
        height: 100%;
        background: #ffffff;
        border-radius: 0.427rem 0.427rem 0px 0px;
        .xiaoxi_top{
            width: 100%;
            height: 1.067rem;
            padding: 0 1.627rem;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .xiao_top_item{
                display: flex;
                align-items: center;
                flex-direction: column;
            }
            .xiao_top_item_f{
                font-size: 0.373rem;
                font-weight: 400;
                color: #383838;
                margin-bottom: 0.107rem;
            }
            .xiao_top_item_x{
                width: 0.48rem;
                height: 0.08rem;
                background: #FF7671;
                border-radius: 0.04rem;
            }
        }
    }
</style>
