<template>
	<div class="capitalExtraction app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="待提佣金" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div v-if="aCapitalExtraction.list.length > 0" class="capitalExtraction_body app_scroll">
			<van-list v-model:loading="aCapitalExtraction.loading" v-model:error="aCapitalExtraction.error" error-text="请求失败，点击重新加载"
				:finished="aCapitalExtraction.finished" finished-text="没有更多了" @load="fOnLoad">
				<div class="capitalExtraction_body_item" v-for="(item,index) in aCapitalExtraction.list" :key="index">
					<div class="capitalExtraction_body_item_a">
						<p>跟单人：<em>{{item.roleName}}</em></p>
					</div>
					<div class="capitalExtraction_body_item_b">
						<div class="capitalExtraction_body_item_b_a">
							<p>投注金额：<em>¥{{item.totalFee}}</em></p>
							<p>中奖金额：<em>¥{{item.afterTaxPrize}}</em></p>
						</div>
						
						<div class="capitalExtraction_body_item_b_b">
							<p>获得佣金：<em>¥{{item.commissionAmount}}</em></p>
						</div>
					</div>
					<p class="capitalExtraction_body_item_c">方案编号：{{item.id}}</p>
				</div>
			</van-list>
		</div>
		<div v-else class="capitalExtraction_body app_scroll">
			<van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')"
				description="目前没有待提佣金记录…" />
		</div>
		<div class="capitalExtraction_bottom">
			<p class="capitalExtraction_bottom_a">合计 <em>{{nUnReceiveCommission}}</em> 元</p>
			
			<van-button class="capitalExtraction_bottom_b" color="linear-gradient(180deg, #FF7671 0%, #E2335D 100%)" round
				@click="fOpenExtract()">
				提到余额
			</van-button>
		</div>
		
		<h-dialog-c title="提到余额" dialogKey="dialogA" :show="oCPData.oYCData.dialogA.showDialog"
			:showCancelButton="oCPData.oYCData.dialogA.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogA.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="capitalExtraction_tips_a">
				<p class="capitalExtraction_tips_a_a">待提佣金：<em>￥{{oTurnBalance.unReceiveCommission}}</em></p>
				<p class="capitalExtraction_tips_a_a" v-if="oTurnBalance.platformCost">平台手续：<em>{{oTurnBalance.platformCost}}</em></p>
				<p class="capitalExtraction_tips_a_b">系统将按照《发单协议》扣除佣金后，将剩余佣金提到您的店内余额中。</p>
			</div>
		</h-dialog-c>
	</div>
</template>

<script>
	import {
		ref,
		reactive
	} from "vue";
	import {
		useStore
	} from "vuex";
	import {
		Toast
	} from 'vant';
	import {
		apiTurnBalance,
		apiGetUntransferBalanceDetail,
		apiGetUntransferBalanceOnePage
	} from "@/api/mine.js";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	export default {
		name: "CapitalStore",
		components: {
			HDialogC
		},
		setup() {
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			
			// 当前页面数据
			const oCPData = reactive({
				oYCData: {
					dialogA: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					}
				}
			})
			
			const aCapitalExtraction = reactive({
				current: 0,
				size: 10,
				list: [],
				error: false,
				loading: false,
				finished: false
			});
			
			const fOnLoad = () => {
				const current = aCapitalExtraction.current + 1;

				apiGetUntransferBalanceOnePage({
					roleId: UserInfo.roleId,
					current,
					size: aCapitalExtraction.size
				}).then(res => {
					aCapitalExtraction.current = current;
					let tData = res && res.result ? res.result : {};
					let tRecords = tData.records ? tData.records : [];
					if (tRecords.length > 0) {
						aCapitalExtraction.list.push(...tRecords);
						if (tData.hasNext) {
							aCapitalExtraction.finished = false;
						} else {
							aCapitalExtraction.finished = true;
						}
					} else {
						aCapitalExtraction.finished = true;
					}
					aCapitalExtraction.loading = false;
				}).catch(() => {
					aCapitalExtraction.loading = false;
					aCapitalExtraction.error = true;
				})
			};
			
			// 提到余额信息
			const nUnReceiveCommission = ref(0);
			// 获取订单详情
			const fGetUntransferBalanceDetail = () => {
				apiGetUntransferBalanceDetail({
					id: UserInfo.roleId
				}).then(res => {
					nUnReceiveCommission.value = res.result ? res.result : '0.00'
				})
			};
			// 提到余额详情
			const oTurnBalance = ref({});
			// 打开--提到余额窗口
			const fOpenExtract = () => {
				fTurnBalance(1);
			};
			// 提到余额(1：获取详情，2：确定提到余额)
			const fTurnBalance = (type, fn) => {
				if (Number(nUnReceiveCommission.value) <= 0) {
					return
				}
				let tData = null;
				if (type === 1) {
					tData = {
						type: 1,
						unReceiveCommission: nUnReceiveCommission.value
					}
				} else if (type === 2) {
					tData = {
						type: 2,
						unReceiveCommission: oTurnBalance.value.unReceiveCommission
					}
				}
				Toast({
					type: "loading"
				});
				// 获取弹框信息
				apiTurnBalance({
					...tData,
					roleId: UserInfo.roleId,
					userId: UserInfo.userId
				}).then(res => {
					let tData = res.result ? res.result : {};
					oTurnBalance.value.unReceiveCommission = tData.unReceiveCommission;
					oTurnBalance.value.platformCost = tData.platformCost;
					if (type === 1) {
						oCPData.oYCData.dialogA.showDialog = true;
					} else if (type === 2) {
						Toast({
							message: '已成功提到店内余额！',
							position: 'bottom'
						});
						fLeft();
						fn(false)
					}
				})
			};
			
			// 弹框关闭之前
			const fBeforeClose = (action, oDialog, fn) => {
				const {
					dialogKey
				} = oDialog;
				if (dialogKey === "dialogA") {
					if (action === "confirm") {
						oCPData.oYCData.dialogA.showDialog = false;
						fTurnBalance(2, fn);
					} else if (action === "cancel") {
						oCPData.oYCData.dialogA.showDialog = false;
						fn(false)
					}
				}
			};
			
			const fLeft = () => {
				fnIsHasHistory("/mine");
			};
			
			fOnLoad();
			fGetUntransferBalanceDetail();
			return {
				oCPData,
				nUnReceiveCommission,
				oTurnBalance,
				aCapitalExtraction,

				fOpenExtract,
				fOnLoad,
				fBeforeClose,

				fLeft,
			}
		}
	}
</script>

<style scoped lang="scss">
	.capitalExtraction {
		width: 100%;
		height: 100%;
		background: #F1F2F7;

		.capitalExtraction_body {
			padding: 0.32rem;
			.capitalExtraction_body_item {
				padding: 0.4rem 0.4rem 0;
				margin-bottom: 0.32rem;
				
				background: #FFFFFF;
				box-shadow: 0px 0.0533rem 0.2133rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.2133rem;
				.capitalExtraction_body_item_a{
					font-size: 0.3733rem;
					font-weight: 500;
					color: #383838;
					line-height: 0.5333rem;
					&>em {
						font-weight: 400;
					}
				}
				
				.capitalExtraction_body_item_b {
					margin-top: 0.6666rem;
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					
					padding: 0 0 0.32rem;
					border-bottom: 0.0266rem solid #EAEAEA;
					.capitalExtraction_body_item_b_a {
						&>p {
							font-size: 0.32rem;
							font-weight: 400;
							color: #383838;
							line-height: 0.4533rem;
							&>em {
								font-size: 0.4266rem;
								font-weight: bold;
								color: #383838;
								line-height: 0.5066rem;
							}
						}
					}
				}
				.capitalExtraction_body_item_b_b {
					&>p {
						font-size: 0.32rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.4533rem;
						&>em {
							font-size: 0.4266rem;
							font-weight: bold;
							color: #E2335D;
							line-height: 0.5066rem;
						}
					}
				}
				.capitalExtraction_body_item_c {
					text-align: right;
					padding: 0.2133rem 0;
					font-size: 0.32rem;
					font-weight: 400;
					color: #979797;
					line-height: 0.4533rem;
				}
			}
		}
		
		.capitalExtraction_bottom {
			padding: 0.4266rem;
			background: #FFFFFF;
			border-radius: 0.2133rem 0.2133rem 0px 0px;
			
			display: flex;
			align-items: center;
			justify-content: space-between;
			.capitalExtraction_bottom_a {
				font-size: 0.3733rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.5333rem;
				&>em {
					font-size: 0.48rem;
					font-weight: bold;
					color: #E2335D;
					line-height: 0.56rem;
				}
			}
		}
	
		.capitalExtraction_tips_a {
			padding: 0.8rem 0.64rem;
		
			.capitalExtraction_tips_a_a {
				font-size: 0.4266rem;
				font-weight: bold;
				color: #383838;
				line-height: 0.5866rem;
		
				&>em {
					font-weight: bold;
					color: #E2335D;
				}
			}
		
			.capitalExtraction_tips_a_b {
				font-size: 0.3733rem;
				font-weight: 400;
				color: #979797;
				line-height: 0.5866rem;
			}
		}
	}
</style>
