<template>
	<div class="recordGD app_flex" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box' :''">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar class="recordGD_bar" title="已选赛事" left-arrow @click-left="fLeft">
				</van-nav-bar>
			</van-sticky>
		</div>
		<div class="recordGD_box" @scroll="listenBottomOut" id="scroll" v-if=" oCPData.unpayCount.length > 0">
			<div class="recordGD_box_item" v-for="(item,index) in oCPData.unpayCount" :key="index">
				<div class="item_top">
					{{item.issueNo}} {{item.weekStr}} {{item.gameNo}}
				</div>
				<div class="item_conter">
					<div class="item_conter_l">
						<img src="~@/assets/images/new/mine_007.png" alt="" class="item_conter_img1" v-if="item.lotteryType == 'JCLQ'">
						<img src="~@/assets/images/new/ic_007.png" alt="" class="item_conter_img1" v-else>
						<div class="item_conter_font">
							<div class="item_conter_font1">
								{{item.gameName}}
							</div>
							<div class="item_conter_font2">
								{{item.homeTeamShortName}} VS {{item.guestTeamShortName}}
							</div>
						</div>
					</div>

<!--					<img src="~@/assets/images/new/mine_006.png" alt="" class="item_conter_img2">-->
				</div>
				<div class="item_bot">
					<div>
						跟单时间：{{fnFormatData(item.documentaryDate, "yyyy-MM-dd hh:mm")}}
					</div>

				</div>
			</div>
		</div>


				<div  class="mt12 recordGD_body app_scroll" v-else>
					<van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')"
						description="暂时没有数据" />
				</div>
	</div>
</template>

<script>
	import {
		reactive
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		getBetEventList,
	} from '@/api/mine.js';
	import {
		fnFormatData,
		fnRecordGDStatusCode,
		uaFun
	} from "@/utils/public.js";
	import {Toast} from "vant";
	// import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	export default {
		name: "RecordGD",
		components: {
			// HDialogC
		},
		setup() {

			const isApp = uaFun()
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			// 查询跟单的统计信息
			const fGetDetail = () => {
				Toast({
					type: "loading"
				});
				getBetEventList({
					current:1,
					size:oCPData.size,
					roleId: UserInfo.roleId
				}).then(res => {
					oCPData.unpayCount = res.result.records
					oCPData.total = res.result.total
					console.log(oCPData.unpayCount.length);
				})
			};
			const oCPData = reactive({
				unpayCount:[],
				size:10,
				total:0
			})

			const listenBottomOut = () => {
				//正文总高度
				// console.log(document.getElementById("scroll").scrollTop);
				let scrollHeight = document.getElementById("scroll").scrollHeight;
				//元素可见区域高度
				let offsetHeight = document.getElementById("scroll").offsetHeight;
				//可滚动容器超出当前窗口显示范围的高度
				let scrollTop = document.getElementById("scroll").scrollTop;
				// //避免切换时读取到异常高度
				// if (scrollTop == 0) {
				// 	scrollHeight= 10000;
				// }
				//scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现scrollHeight< (offsetHeight+ scrollTop)的情况，严格来讲，是接近底部。
				//console.log(scrollHeight + " " + offsetHeight+ " " + scrollTop);

				// console.log("加载中~");
				if (scrollTop >= scrollHeight - offsetHeight) {
					if(oCPData.total != oCPData.unpayCount.length){
						//此处添加自定义操作
						oCPData.size+=20
						fGetDetail()
					}

				}


			}
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/mine");
			};
			fGetDetail()
			return {
				fnFormatData,
				listenBottomOut,
				oCPData,
				fnRecordGDStatusCode,
				fLeft,
				isApp
			}
		}
	}
</script>

<style scoped lang="scss">
	.recordGD_box{
		padding: 0.32rem;
		box-sizing: border-box;
		height: 100%;
		overflow-y: auto;
		.recordGD_box_item{
			margin-bottom: 0.32rem;
			width: 100%;
			height: 3.787rem;
			background: #FFFFFF;
			box-shadow: 0px 0.053rem 0.213rem 0px rgba(224,228,246,0.5);
			border-radius: 0.213rem;
			padding: 0 0.4rem;
			box-sizing: border-box;
			.item_top{
				font-size: 0.32rem;
				font-weight: 600;
				color: #979797;
				line-height: 0.88rem;
				height: 0.88rem;
				border-bottom: 0.027rem solid #EAEAEA;
			}
			.item_conter{
				height: 1.973rem;
				border-bottom: 0.027rem solid #EAEAEA;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.item_conter_l{
					display: flex;
					.item_conter_img1{
						width: 1.28rem;
						height: 1.44rem;
					}
					.item_conter_font{
						margin-left: 0.16rem;
						padding-top: 0.133rem;
						box-sizing: border-box;
						font-size: 0.373rem;
						color: #383838;
						.item_conter_font1{
							font-weight: 600;
							margin-bottom: 0.133rem;
						}
						.item_conter_font2{
							font-weight: 400;
						}
					}

				}
				.item_conter_img2{
					width: 0.64rem;
					height: 0.64rem;
				}
			}
			.item_bot{
				font-size: 0.32rem;
				font-weight: 400;
				color: #979797;
				height: 0.88rem;
				line-height: 0.88rem;
			}
		}
	}
	.recordGD {
		width: 100%;
		height: 100%;
		background: #F1F2F7;
		box-sizing: border-box;

		.recordGD_body {
			padding: 0 0.32rem;
		}
	}

	.recordGD_zIndex {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 5;
		background: #F1F2F7;
		border-radius: 0px 0px 8px 8px;
	}

	.recordGD_HDropdownMenu {
		padding: 0.1066rem 0.32rem;
		display: flex;
		align-items: center;
	}

	.HDropdownMenu {
		position: relative;

		.HDropdownMenu_show {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.2133rem 0.5066rem;

			min-width: 2.5866rem;
			box-sizing: border-box;

			&>span {
				font-size: 0.32rem;
				font-weight: 500;
				color: #383838;
				line-height: 0.4533rem;
			}

			&>img {
				margin-left: 0.1066rem;
				width: 0.1866rem;
				height: auto;
			}
		}

		.HDropdownMenu_show--select {
			&>span {
				color: #FF7671;
			}

			&>img {
				margin-left: 0.1066rem;
				width: 0.1866rem;
				height: auto;
			}
		}

		.HDropdownMenu_content {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 30;

			background: #FFFFFF;
			box-shadow: 0px 0.0533rem 0.2666rem 0px rgba(224, 228, 246, 0.8);
			border-radius: 0.2133rem;

			.HDropdownMenu_content_title {
				display: flex;
				align-items: center;
				padding: 0.2133rem 0.5066rem;

				&>span {
					font-size: 0.32rem;
					font-weight: 500;
					color: #FF7671;
					line-height: 0.4533rem;
				}

				&>img {
					margin-left: 0.1066rem;
					width: 0.1866rem;
					height: auto;
				}
			}

			.HDropdownMenu_content_option {
				margin: 0.2666rem 0.48rem;
				padding: 0.0266rem 0;
				text-align: center;

				font-size: 0.32rem;
				font-weight: 500;
				color: #383838;
				line-height: 0.4533rem;
			}

			.HDropdownMenu_content_option--selected {
				color: #FF7671;
			}
		}

		.HDropdownMenu_mask {
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 10;
		}
	}

	.HDropdownMenu_search {
		flex: 1;

		.HDropdownMenu_show {
			justify-content: flex-end;
		}
	}

	.HDropdownMenu_search_mask {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 4;
		background: #000000;
		opacity: 0.72;
	}

	.recordGD_body {
		padding: 0 0.32rem;

		.recordGD_body_card {
			background: #FFFFFF;
			box-shadow: 0px 0.0533rem 0.2133rem 0px rgba(224, 228, 246, 0.5);
			border-radius: 0.2133rem;
			padding: 0.4rem 0.4rem 0;
			margin-bottom: 0.32rem;

			.recordGD_body_card_a {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 0.4533rem;

				.recordGD_body_card_a_a {
					display: flex;
					align-items: center;

					.recordGD_body_card_a_a_a--red {
						width: 0.1066rem;
						height: 0.48rem;
						background: #F21B8E;
						border-radius: 0.0533rem;
					}

					.recordGD_body_card_a_a_a--blue {
						width: 0.1066rem;
						height: 0.48rem;
						background: #033EC2;
						border-radius: 0.0533rem;
					}

					.recordGD_body_card_a_a_b {
						margin-left: 0.1066rem;
						font-size: 0.3733rem;
						font-weight: 600;
						color: #383838;
						line-height: 0.5333rem;
					}

					.recordGD_body_card_a_a_c {
						margin-left: 0.1066rem;
						font-size: 0.3733rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.5333rem;
					}
				}

				.recordGD_body_card_a_b {
					font-size: 0.32rem;
					font-weight: 400;
					color: #383838;
					line-height: 0.4533rem;

					&>em {
						font-weight: 500;
					}
				}
			}

			.recordGD_body_card_b {
				padding-left: 0.2133rem;
				margin-bottom: 0.4rem;
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				.recordGD_body_card_b_a {
					.recordGD_body_card_b_a_a {
						font-size: 0.32rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.4533rem;
						margin-bottom: 0.16rem;

						&>em {
							font-size: 0.4266rem;
							font-weight: bold;
							color: #383838;
							line-height: 0.5066rem;
						}
					}

					.recordGD_body_card_b_a_b {
						font-size: 0.32rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.4533rem;

						&>em {
							font-size: 0.32rem;
							font-weight: 400;
							color: #383838;
							line-height: 0.4533rem;
						}
					}
				}

				.recordGD_body_card_b_b {
					font-size: 0.32rem;
					font-weight: 400;
					color: #383838;
					line-height: 0.4533rem;

					&>em {
						font-size: 0.4266rem;
						font-weight: bold;
						color: #E2335D;
						line-height: 0.5066rem;
					}
				}
			}

			.recordGD_body_card_c {
				border-top: 0.0266rem solid #EAEAEA;
				padding: 0.2133rem 0;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.recordGD_body_card_c_a {
					font-size: 0.32rem;
					font-weight: 400;
					color: #979797;
					line-height: 0.4533rem;
				}
			}
		}
	}

	.recordGD_bottom {
		padding: 0.4266rem;
		background: #FFFFFF;
		border-radius: 0.2133rem 0.2133rem 0px 0px;

		display: flex;
		align-items: center;
		justify-content: space-between;

		.recordGD_bottom_a {
			.recordGD_bottom_a_a {
				font-size: 0.3733rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.5333rem;

				&>em {
					font-size: 0.48rem;
					font-weight: bold;
					color: #383838;
					line-height: 0.56rem;
				}
			}

			.recordGD_bottom_a_b {
				font-size: 0.3733rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.5333rem;

				&>em {
					font-size: 0.48rem;
					font-weight: bold;
					color: #E2335D;
					line-height: 0.56rem;
				}
			}
		}
	}

	.recordGD_tips {
		text-align: center;
		padding: 0.2666rem 0.8533rem;
		font-size: 0.4266rem;
		font-weight: 400;
		color: #383838;
		line-height: 0.5866rem;
	}
</style>
