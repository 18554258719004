<template>
	<div class="noticeDetail app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="消息" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="noticeDetail_body app_scroll">
			<div class="noticeDetail_body_content">
				<p class="noticeDetail_body_content_title">{{ oCPData.noticeTitle }}</p>
				<p class="noticeDetail_body_content_time">{{ fnFormatData(oCPData.sendTime) }}</p>
				<p class="noticeDetail_body_content_detail">
					{{ oCPData.noticeContent}}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		computed
	} from "vue";
	import {
		useStore
	} from "vuex";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnFormatData
	} from "@/utils/public.js";
	import {
		apiReadMarkBatch
	} from "@/api/mine.js";
	
	export default {
		name:"NoticeDetail",
		setup() {
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			const oCPData = computed(() => store.state.other.noticeDetail);
			// 将消息
			const fRead = () => {
				if (!oCPData.value.isRead) {
					apiReadMarkBatch({
						ids: oCPData.value.id,
						roleId: UserInfo.roleId
					})
				}
			};
			fRead();
			const fLeft = () => {
				fnIsHasHistory("/mine");
			};
			return {
				oCPData,
				fnFormatData,
				fLeft,
			}
		}
	}
</script>

<style scoped lang="scss">
	.noticeDetail {
		width: 100%;
		height: 100%;
		background: #F1F2F7;
		box-sizing: border-box;
		.noticeDetail_body {
			padding: 0.32rem;
			.noticeDetail_body_content {
				padding: 0.4rem;
				border-radius: 0.213rem;
				background: #ffffff;
				&_title{
					font-size: 0.48rem;
					font-weight: bold;
					color: #333333;
				}
				&_time{
					margin-top: 0.2666rem;
					font-size: 0.32rem;
					font-weight: 500;
					color: #666666;
				}
				&_detail{
					text-indent:2em;
					display: inline-block;
					word-break: break-all;
					font-size: 0.32rem;
					font-weight: 500;
					color: #666666;
					margin-top: 0.32rem;
					line-height: 0.48rem;
				}
			}
		}
	}
</style>
