<template>
	<div class="capitalStore app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="店内余额" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div v-if="aCapitalStore.list.length > 0" class="capitalStore_body app_scroll">
			<van-list v-model:loading="aCapitalStore.loading" v-model:error="aCapitalStore.error" error-text="请求失败，点击重新加载"
				:finished="aCapitalStore.finished" finished-text="没有更多了" @load="fOnLoad">
				<div class="capitalStore_body_item" v-for="(item,index) in aCapitalStore.list" :key="index">
					<div class="capitalStore_body_item_a">
						<div class="capitalStore_body_item_a_a">
							<p class="capitalStore_body_item_a_a_a">{{item.amountType}}</p>
							<p class="capitalStore_body_item_a_a_b" :class="{'cE2335D':item.direction == '1'}">{{item.direction == '1'? '+' :'-'}}{{item.amount}}</p>
						</div>
						
						<div class="capitalStore_body_item_a_b">
							<p>余：{{item.availableAmount}}</p>
						</div>
					</div>
					<p class="capitalStore_body_item_b">{{fnFormatData(item.created,"yyyy-MM-dd hh:mm")}}</p>
				</div>
			</van-list>
		</div>
		<div v-else class="app_scroll">
			<van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')"
				description="目前店内余额记录…" />
		</div>
	</div>
</template>

<script>
	import {
		reactive
	} from "vue";
	import {
		useStore
	} from "vuex";
	import {
		apiAvailableCapital
	} from "@/api/mine.js";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnFormatData
	} from "@/utils/public.js";
	export default {
		name: "CapitalStore",
		setup() {
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			// 方案--全部
			const aCapitalStore = reactive({
				current: 0,
				size: 10,
				list: [],
				error: false,
				loading: false,
				finished: false
			});
			// 加载完成--方案--待支付
			const fOnLoad = () => {
				const current = aCapitalStore.current + 1;

				apiAvailableCapital({
					roleId: UserInfo.roleId,
					userId: UserInfo.userId,
					current,
					size: aCapitalStore.size
				}).then(res => {
					aCapitalStore.current = current;
					let tData = res && res.result ? res.result : {};
					let tRecords = tData.records ? tData.records : [];
					if (tRecords.length > 0) {
						aCapitalStore.list.push(...tRecords);
						if (tData.hasNext) {
							aCapitalStore.finished = false;
						} else {
							aCapitalStore.finished = true;
						}
					} else {
						aCapitalStore.finished = true;
					}
					aCapitalStore.loading = false;
				}).catch(() => {
					aCapitalStore.loading = false;
					aCapitalStore.error = true;
				})
			};
			
			const fLeft = () => {
				fnIsHasHistory("/mine");
			};
			
			
			fOnLoad();
			return {
				aCapitalStore,

				fnFormatData,
				fOnLoad,

				fLeft,
			}
		}
	}
</script>

<style scoped lang="scss">
	.capitalStore {
		width: 100%;
		height: 100%;
		background: #F1F2F7;

		.capitalStore_body {
			padding: 0.32rem;
			.capitalStore_body_item {
				padding: 0.4rem 0.4rem 0;
				margin-bottom: 0.32rem;
				
				background: #FFFFFF;
				box-shadow: 0px 0.0533rem 0.2133rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.2133rem;
				.capitalStore_body_item_a {
					padding-bottom: 0.3733rem;
					border-bottom: 0.0266rem solid #EAEAEA;
					.capitalStore_body_item_a_a {
						display: flex;
						align-items: center;
						justify-content: space-between;
						
						.capitalStore_body_item_a_a_a {
							font-size: 0.3733rem;
							font-weight: 500;
							color: #383838;
							line-height: 0.5333rem;
						}
						.capitalStore_body_item_a_a_b {
							font-size: 0.4266rem;
							font-weight: bold;
							color: #383838;
							line-height: 0.5066rem;
						}
					}
				}
				.capitalStore_body_item_a_b {
					display: flex;
					justify-content: flex-end;
					&>p {
						font-size: 0.32rem;
						font-family: PingFangSC-Semibold, PingFang SC;
						font-weight: 600;
						color: #979797;
						line-height: 0.4533rem;
					}
				}
				.capitalStore_body_item_b {
					padding: 0.2133rem 0;
					font-size: 0.32rem;
					font-weight: 400;
					color: #979797;
					line-height: 0.4533rem;
				}
			}
		}
	}
</style>
